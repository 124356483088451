<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/smallVideo' }">小视频</el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form label-width="120px">
      <el-form-item label="封面图片">
        <upload :src.sync="fmpic"></upload>
      </el-form-item>
      <el-form-item label="视频地址">
        <el-input v-model="vediosrc" placeholder="请输入视频地址" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="绑定医生">
        <el-input
          placeholder="请输入精确的姓名或电话号"
          clearable
          prefix-icon="el-icon-search"
          v-model="search"
          style="width: 15%;float: left;"
          @clear="clearsearch"
        >
        </el-input>
        <el-button type="primary" style="margin-left:20px;float: left;" @click="query"
          >查询</el-button
        >
        <div
          v-if="list.length != ''"
          style="width: 100%;float: left;text-align: left;margin-top: 5px;"
        >
          <el-checkbox v-model="docradio" :true-label="list.id">{{ list.name }}</el-checkbox>
        </div>
      </el-form-item>
      <el-form-item label="是否推荐到首页" style="text-align: left;">
        <el-radio v-model="radio" label="1">是</el-radio>
        <el-radio v-model="radio" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          v-model="sortnum"
          placeholder="数字越大排名越靠前"
          autocomplete="off"
          style="width: 15%;float: left;"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="文章标题">
        <el-input v-model="articleT" placeholder="请输入标题" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="文章详情">
        <el-input type="textarea" :rows="5" v-model="content"></el-input>
      </el-form-item>
      <div style="margin-top: 80px;">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import upload from "../components/upload";
export default {
  components: {
    upload,
  },
  data() {
    return {
      type: "",
      id: "",
      articleT: "",
      content: "",
      editorOption: {
        placeholder: "编辑内容",
      },
      title: "添加小视频",
      fmpic: "",
      date: "",
      radio: "0",
      sortnum: "",
      toptitle: "",
      topath: "",
      vediosrc: "",
      search: "",
      list: "",
      docradio: false,
      doc_id: "",
      isvideocanplay: false,
    };
  },
  watch: {
    vediosrc(o, n) {
      if (o != "") {
        this.check(this.vediosrc);
      }
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    if (this.id) {
      //读取详情接口
      this.axios.get("/video/get_gu_video_by_id?id=" + this.id).then((res) => {
        if (res.data.code == 0) {
          // console.log(res)
          this.articleT = res.data.result.title;
          this.fmpic = res.data.result.video_pic;
          this.vediosrc = res.data.result.url;
          this.search = res.data.result.doc_name;
          this.radio = res.data.result.is_homepage.toString();
          this.sortnum = res.data.result.sort;
          this.content = res.data.result.des;
          if (
            res.data.result.doc_id != "" &&
            res.data.result.doc_id != null &&
            res.data.result.doc_id != undefined
          ) {
            this.doc_id = res.data.result.doc_id;
          }
        } else {
          console.log(res);
        }
      });
    }
    if (this.type != "new") {
      this.title = "编辑小视频";
    }
  },
  methods: {
    check(value) {
      var video = document.createElement("video");
      video.src = value;
      var _this = this;
      video.onerror = function() {
        // alert("Can not play");
        _this.isvideocanplay = false;
        _this.$message({
          message: "请填写正确的视频地址",
          type: "error",
        });
      };
      video.oncanplay = function() {
        // alert("Can start playing video");
        _this.isvideocanplay = true;
      };
    },
    clearsearch() {
      this.doc_id = "";
      this.docradio = false;
      this.list = [];
    },
    query() {
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.search, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.list = rs.data.result[0];
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      this.$router.push("/smallVideo");
    },
    sub() {
      let str, url;
      if (this.docradio == false) {
      } else {
        this.doc_id = this.docradio;
      }
      let form = {
        title: this.articleT,
        url: this.vediosrc,
        des: this.content,
        video_pic: this.fmpic,
        doc_id: this.doc_id,
        sort: this.sortnum,
        is_homepage: this.radio,
      };
      if (this.type != "new") {
        form.id = this.id;
        str = "修改成功";
        url = "/video/update_gu_video";
      } else {
        str = "添加成功";
        url = "/video/insert_gu_video";
      }
      if (form.video_pic == "") {
        this.$message({
          message: "请上传封面",
          type: "error",
        });
        return;
      }
      if (form.title == "") {
        this.$message({
          message: "请填写标题",
          type: "error",
        });
        return;
      }
      if (form.des == "") {
        this.$message({
          message: "请添加详情",
          type: "error",
        });
        return;
      }
      if (form.url == "") {
        this.$message({
          message: "请添写视频地址",
          type: "error",
        });
        return;
      } else {
        if (!this.isvideocanplay) {
          this.$message({
            message: "请添写正确的视频地址",
            type: "error",
          });
          return;
        }
      }
    //   console.log(form);
      this.axios.post(url, this.qs.stringify(form))
          .then((res) => {
              if (res.data.code == 0) {
                  this.$message({
                      message: str,
                      type: 'success'
                  });
                  this.$router.push('/smallVideo');
              } else {
                  this.$message(res.data.msg);
              }
          });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
